
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

const list = [
    {
        name: '聚臻城地标供应链平台',
        href: 'jlscp.html'
    }
]


// joinIn.addEventListener('click', () => {
//     //跳转
//     window.open('/joinIn.html','_self');
// })

window.addEventListener('load', () => {
    toAboutUsAdJoinIn()
    const nav = document.querySelector('.nav-ul');
    const line = document.querySelector('.underline');
    //定时器
    let timer;
   
    const path = window.location.pathname;
    if(path == '/iprp.html'){
       const navbar = document.querySelector('.navbar')
       navbar.style.backgroundColor = 'rgba(41, 52, 60,0.9)'
    }else{
        navbarScroll()
        init(nav, line)
    }

    
      // 添加点击事件监听器
      nav.addEventListener('click', (event) => {
        if (event.target.classList.contains('nav-item')) {
            console.log('点击了:', event.target.textContent);
            // 执行点击后的逻辑
        }
    });

    // 添加鼠标悬停事件监听器
    nav.addEventListener('mouseover', (event) => {
        if (event.target.classList.contains('nav-item')) {
            console.log('鼠标悬停在:', event.target.textContent);
            //获取当前元素距离父元素的左侧距离
            clearTimeout(timer);
            let offsetLeft;
            if(event.target.textContent == '知识产权维权')  {
                 offsetLeft = event.target.offsetLeft;
            }else{
                 offsetLeft = event.target.offsetLeft+30;
            }
            //设置下划线的宽度和位置
            line.style.left = `${offsetLeft}px`;
            // 执行鼠标悬停时的逻辑


        }
    });

    // 添加鼠标移出事件监听器
    nav.addEventListener('mouseout', (event) => {
        if (event.target.classList.contains('nav-item')) {
            console.log('鼠标移出了:', event.target.textContent);
            // 执行鼠标移出时的逻辑

            timer = setTimeout(() => {
                clearTimeout(timer);
                init(nav, line)
            }, 500);
        }
    });
    //鼠标点击事件
    nav.addEventListener('click', (event) => {
        if (event.target.classList.contains('nav-item')) {

            const index = Array.from(nav.children).indexOf(event.target);
            //跳转
            if(index == 0){
                //设置target跳转方式
                window.open('/jlscp.html','_self');
                
            }else if(index == 1){
                window.open('/ciprip.html','_self');
            }else if(index == 2){
                window.open('/ipfes.html','_self');
            }else if(index == 3){
                window.open('/rightsProtection.html','_self');
            }else if(index == 4){
                window.open('/dg-ad.html','_self');
            }
    
        }
    });
   
})

//默认下标
function init(nav, line) {
    //从本地获取index
    let index;
    //获取路由信息
    const path = window.location.pathname;
    if(path == '/'){
        index = -1
    }
    if(path == '/jlscp.html'){
        index = 0
    }
    if(path == '/ciprip.html'){
        index = 1
    }
    if(path == '/ipfes.html'){
        index = 2
    }
    if(path == '/rightsProtection.html'){
        index = 3
    }
    if(path == '/dg-ad.html'){
        index = 4
    }

    console.log('path',path);
    // const index = -1;
    //取nav第二个子元素
    const item = nav.children[index];
    //获取当前元素距离父元素的左侧距离
    let offsetLeft;
    if(index == -1){
        offsetLeft = -100;
    }else if(index == 3){
        offsetLeft = item?.offsetLeft;
    }else{
        offsetLeft = item?.offsetLeft+30;
    }
    // let offsetLeft = index == -1?-100:item.offsetLeft+30;
    //设置下划线的宽度和位置
    line.style.left = `${offsetLeft}px`;
    //过渡
    line.style.transition = 'all 0.5s ease';

}
function navbarScroll(){
    //监听页面的滚动 超出200px时 显示导航栏背景 使用gsap和scrollTrigger插件
    const navbar = document.querySelector('.navbar');
    ScrollTrigger.create({
        trigger:document.body,
        start: "100 -100", // 页面滚动到 100px 时触发
        onEnter: () => gsap.to(navbar, { backgroundColor: "rgba(41, 52, 60,0.9)", duration: 0.1 }),
        onLeaveBack: () => gsap.to(navbar, { backgroundColor: "transparent", duration: 0.1 }),
        toggleActions: "play none none reverse", // 定义进入和回退时的行为
        // markers: true

    })
  
}
function toAboutUsAdJoinIn(){
    const toAboutUs = document.querySelector('.btn_about_us');
    const toJoinIn = document.querySelector('.join_in');
    toAboutUs.addEventListener('click', () => {
        //跳转
        window.open('/aboutUs.html','_self');
    })
    toJoinIn.addEventListener('click', () => {
        //跳转
        window.open('/joinUs.html','_self');
    })

}